<template>
  <MainCard @scrollToProjects="scrollToProjects" @scrollToCertifications="scrollToCertifications"/>
  <div ref="projectsCard">
    <ProjectsCard />
  </div>
  <div ref="certificationsCard">
    <CertificationsCard />
  </div>
</template>

<script>
import MainCard from './components/MainCard.vue'
import ProjectsCard from './components/ProjectsCard.vue'
import CertificationsCard from './components/CertificationsCard.vue'

export default {
  name: 'App',
  components: {
    MainCard,
    ProjectsCard,
    CertificationsCard
  },
  methods: {
    scrollToProjects() {
      const projectsCard = this.$refs.projectsCard;
      if (projectsCard) {
        projectsCard.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToCertifications() {
      const certificationsCard = this.$refs.certificationsCard;
      if (certificationsCard) {
        certificationsCard.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
