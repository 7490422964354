<template>
  <div class="wrapper">
    <div class="main-container">
    <div class="container-one">
        <div class="profile">
            <img src="../assets/Hoffmann.jpg" alt="Brandon's picture">
            <h3 >Brandon Hoffman</h3>
            <p>Systems Administrator & Cloud Architect</p>
        </div>
        <div class="social-links">            
            <div class="link">
                <a href="https://github.com/boarding-and-coding">
                    <img src="../assets/github.png" alt="github icon">
                    <h2>GitHub</h2>
                </a>
            </div>
            <div class="link">
                <a href="https://www.linkedin.com/in/hoff300/">
                    <img src="../assets/linkedin.png" alt="linkedin icon">
                    <h2>LinkedIn</h2>
                </a>
            </div>
        </div>
    </div>
    <div class="container-two">
        <div class="about-me">
            <h1>About Me</h1>
            <p>Kansas State alumni. Moved to Montana to hone my technical infrastructure skills while romping around Glacier National Park. After a few years I moved on up to system administrator and took a liking to devops engineering and cloud architecture. Most of my cloud experience is with the Google Cloud Platform.</p>
        </div>
        <div class="technology-section">
            <h1>Technologies Studied</h1>
            <div class="logos">
                <img src="../assets/js.png" alt="javascript icon">
                <img src="../assets/html.png" alt="html icon">
                <img src="../assets/css.png" alt="css icon">
                <img src="../assets/gitworkflow.png" alt="gitops icon">
                <img src="../assets/terraform.png" alt="terraform icon">
                <img src="../assets/gcpicon.png" alt="gcp icon">
            </div>
        </div>
        <div class="button-container">
            <div class="projects-button">
                <a @click.prevent="scrollToProjects">My Projects</a>
            </div>
            <div class="projects-button">
                <a @click.prevent="scrollToCertifications">My Certifications</a>
            </div>
            <div class="projects-button">
                <a @click.prevent="openResume">My Resume</a>
            </div>
        </div>
    </div>
    </div>
  </div>  

</template>

<script>
export default {
    methods: {
        scrollToProjects() {
            this.$emit('scrollToProjects');
        },
        scrollToCertifications() {
            this.$emit('scrollToCertifications');
        },
        openResume() {
            window.open('/resume.pdf', '_blank');
        }
    }
};
</script>

<style scoped>
    .wrapper{
        display: flex;
        justify-content: center;
    }

    img{
        height: 20px;
    }

    .main-container{
        display: flex;
        flex-direction: row;
        background-color: #a69d9a;
        border-radius: 25px;
        width: 80%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .container-one{
        background-color: cornsilk;
        padding: 2.5%;
        margin: 1%;
        width: 30%;
        border-radius: 25px;
    }

    .profile img{
        height: 10rem;
        border-radius: 50%;
    }

    .link a{
        text-decoration: none;
        color:#2c3e50;
        font-size: 10px;
    }

    .link img{
        height: 2rem;
    }

    .container-two{
        background-color: #d0886e;
        padding: 2.5%;
        margin: 1%;
        width: 70%;
        border-radius: 25px;
    }

    .about-me{
        text-align: left;

    }

    .logos img{
        height: 2.5rem;
    }

    .button-container{
        margin-top: 5%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .projects-button{
        background-color: darkgray;
        border-radius: 15px;
        padding: 5px;
        margin: 5px;
    }

    .projects-button a{
        cursor: pointer;
        text-decoration: none;
        color:#2c3e50;
    }
</style>