<template>
  <div class="main-container">
    <div class="project-card" v-for="(item, index) in projectData.ProjectsArray" v-bind:key="index">
      <h2>{{ item.Title }}</h2>
      <a :href="item.Link1" target="_blank">
        <img :src="getImageUrl(item.Image)" alt="project image">
      </a>
      <p>{{ item.About }}</p>
    </div>
  </div>
</template>

<script>
import jsonData from "../../projects.json";

export default {
  data() {
    return {
      projectData: jsonData
    };
  },
  methods: {
    getImageUrl(image) {
      return require(`../assets/${image}`);
    }
  }
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.project-card {
  margin: 10px;
  padding: 10px;
  width: 300px;
  height: 400px;
  background-color: white;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.project-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>